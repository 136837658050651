import React from 'react';
import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#fff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2rem',
    },
  },
  paraContent: {
    color: '#4B5B69',
    textAlign: 'center',
    paddingBottom: '3rem',
    fontFamily: ['Roboto', 'sans-serif'].join(),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0rem',
      textAlign: 'left',
    },
  },
  slideItem: {
    height: '400px',
    width: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '20px',
    position: 'relative',
    float: 'left',
    margin: '50px 10px 12px 0',
    boxShadow: '0 6px 10px 0px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'relative',
      height: '450px',
      zIndex: '2',
      margin: '0 0 30px 0',
      borderRadius: '20px 20px 0 0',
      border: 'none',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: '300px',
    },
  },
  slidePadding: {
    padding: '0',
    position: 'relative',
  },
  slideCard: {
    padding: theme.spacing(3),
    position: 'absolute',
    marginLeft: '10px',
    top: '0',
    background: '#fff',
    width: '50%',
    right: '0',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    boxShadow: '0 6px 10px 0px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      width: '100%',
      position: 'relative',
      zIndex: '1',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  slideCardText: {
    color: '#4B5B69',
    fontSize: '1.75rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '400;',
    margin: '0 0 20px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  slideCardName: {
    color: '#002D5C',
    fontSize: '1.25rem',
    fontWeight: '700',
    margin: '0',
    display: 'flex',
  },
  beginQuote: {
    color: '#002D5C',
    height: '40px',
    width: 'auto',
  },
  // slider dots

  slickList: {
    '& .slick-list': {
      overflow: 'visible !important',
    },
  },

  slickDots: {
    '& .slick-dots': {
      position: 'relative',
      bottom: '0px',
      color: '#002d5c',
      opacity: '1',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: '460px',
      },
      [theme.breakpoints.down('xs')]: {
        top: '310px',
      },
    },
    '& .slick-dots li button:before': {
      fontSize: '15px', // Dot Size
      color: '#002d5c',
    },
    '& .slick-dots active li button:before': {
      color: '#002d5c',
    },
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    minWidth: '100%',
    border: '5px white solid',
    boxShadow:
      '0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
    zIndex: 2,
    marginTop: '4rem',
    borderRadius: '20px',
    overflow: 'hidden',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

const GeneralTestimonial = ({ testimonials, video }) => {
  let settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
  };
  const classes = useStyles();

  return (
    <>
      {video ? (
        <Grid container justifyContent='center'>
          <Grid
            xs={8}
            style={{ borderRadius: '0 0 20px 20px', overflow: 'hidden' }}
          >
            <div className={classes.playerWrapper}>
              <ReactPlayer
                url={video}
                className={classes.reactPlayer}
                height='100%'
                width='100%'
                controls={true}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <div
          style={{ zIndex: '2', position: 'relative' }}
          className={classes.slickDots}
        >
          <Slider className={classes.slickList} {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <div className={classes.slidePadding}>
                  <div
                    className={classes.slideItem}
                    style={{
                      backgroundImage: `url(${testimonial.image?.asset.gatsbyImageData.images.fallback.src})`,
                    }}
                    role='img'
                    alt='testimonial'
                  ></div>
                  <div className={classes.slideCard}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 448 512'
                      style={{ width: '40px', height: 'auto' }}
                    >
                      <path
                        opacity='0.4'
                        fill='#002D5C'
                        d='M256 216l0 72 0 32 0 32c0 35.3 28.7 64 64 64l64 0c35.3 0 64-28.7 64-64l0-64c0-35.3-28.7-64-64-64l-64 0 0-8c0-30.9 25.1-56 56-56l8 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-8 0c-66.3 0-120 53.7-120 120z'
                      />
                      <path
                        fill='#002D5C'
                        d='M120 96C53.7 96 0 149.7 0 216l0 72 0 32 0 32c0 35.3 28.7 64 64 64l64 0c35.3 0 64-28.7 64-64l0-64c0-35.3-28.7-64-64-64l-64 0 0-8c0-30.9 25.1-56 56-56l8 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-8 0z'
                      />
                    </svg>
                    <p className={classes.slideCardText} role='heading'>
                      {testimonial.testimonialText}
                    </p>
                    <p className={classes.slideCardName} role='heading'>
                      - {testimonial.nameAndTitle} {testimonial.company}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default GeneralTestimonial;
